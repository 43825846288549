<template>
  <div
    id="my-info-phone"
    class="co-flex-col px-6"
  >
    <span class="text-h5 my-4">{{ title }}</span>
    <v-divider />
    <div class="co-w-full co-h40" />
    <v-form
      ref="form"
      lazy-validation
    >
      <v-text-field
        v-model="txtPhone.value"
        :label="txtPhone.label"
        outlined
        required
        :disabled="txtPhone.disabled"
        :prepend-inner-icon="txtPhone.prependInner"
        :rules="txtPhone.disabled ? [] : phoneRules"
      />

      <v-text-field
        v-model="txtCode.value"
        :label="txtCode.label"
        outlined
        required
        :prepend-inner-icon="txtCode.prependInner"
        :rules="codeRules"
      >
        <template #append>
          <span
            class="pt-1"
            :class="[txtCode.color ? `${txtCode.color}--text` : 'blue-grey--text']"
            style="cursor: pointer;"
            @click="sendMsgCode"
          >{{ txtCode.appendInner }}</span>
        </template>
      </v-text-field>

      <v-text-field
        v-model="txtPassword1.value"
        :label="txtPassword1.label"
        outlined
        type="password"
        required
        :prepend-inner-icon="txtPassword1.prependInner"
        :rules="pwd1Rules"
      />
      <v-text-field
        v-model="txtPassword2.value"
        :label="txtPassword2.label"
        outlined
        type="password"
        required
        :prepend-inner-icon="txtPassword2.prependInner"
        :rules="pwd2Rules"
      />
      <v-btn
        depressed
        :loading="btnOk.loading"
        :color="btnOk.color"
        class="mt-6"
        height="40"
        width="120"
        @click="onSubmit"
      >
        {{ btnOk.text }}
      </v-btn>
    </v-form>
    <dialog-alert ref="dlgAlert" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import lib from '@/api/co.lib'

  export default {
    name: 'InfoBindMobile',
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    props: {
      info: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        title: '修改密码',
        txtPhone: {
          label: '手机号码',
          prependInner: 'mdi-cellphone',
          appendInner: '',
          disabled: false,
          value: ''
        },
        txtPassword1: {
          label: '输入新密码',
          prependInner: 'mdi-lock-outline',
          value: ''
        },
        txtPassword2: {
          label: '确认新密码',
          prependInner: 'mdi-lock-outline',
          value: ''
        },
        txtCode: {
          label: '验证码',
          prependInner: 'mdi-shield-key-outline',
          appendInner: '获取验证码',
          value: '',
          timer: null,
          count: 60,
          disabled: false,
          color: 'black'
        },
        phoneRules: [
          val => !!val || '必须填写手机号码',
          val => /^[1][3-9][0-9]{9}$/.test(val) || '请输入正确的手机号码'
        ],
        codeRules: [
          val => !!val || '必须填写验证码',
          val => /^[0-9]{6}$/.test(val) || '请输入正确的验证码'
        ],
        pwd1Rules: [
          val => !!val || '必须输入新密码',
          val => val.length >= 6 || '新密码至少6位'
        ],
        pwd2Rules: [
          val => !!val || '必须输入确认密码',
          val => val.length >= 6 || '确认密码至少6位'
        ],
        btnOk: {
          loading: false,
          color: 'primary',
          text: '确定'
        }
      }
    },
    watch: {
      info: {
        handler (val) {
          if (api.comm.isPhone(val.mobile)) {
            this.txtPhone.value = val.mobileMask || ''
            this.txtPhone.disabled = true
          } else {
            this.txtPhone.disabled = false
            this.txtPhone.value = ''
          }
        },
        immediate: true
      }
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      clearCodeTimer () {
        if (this.txtCode.timer) {
          clearInterval(this.txtCode.timer)
          this.txtCode.timer = null
        }
      },
      startCodeTimer () {
        const me = this
        const decreaseCount = function () {
          me.txtCode.count -= 1
          if (me.txtCode.count < 1) {
            me.clearCodeTimer()
            me.txtCode.appendInner = '获取验证码'
            me.txtCode.disabled = false
            me.txtCode.color = 'black'
          } else {
            me.txtCode.appendInner = `(${me.txtCode.count}S)后重发`
          }
        }

        this.txtCode.count = 60
        this.txtCode.disabled = true
        this.txtCode.color = 'grey'
        this.txtCode.timer = setInterval(decreaseCount, 1000)
      },
      sendMsgCode () {
        const me = this
        const executing = function () {
          me.startCodeTimer()
        }

        const executed = function (res) {
          console.log('sendMsgCode, res: %o', res)
          if (res.status) {
            me.$notify({
              title: '成功提示',
              message: '验证码发送成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '错误提示',
              message: '验证码发送失败！',
              type: 'error'
            })
          }
        }

        let mobile = ''
        if (this.txtPhone.disabled) {
          mobile = this.info.mobile
        } else {
          mobile = this.txtPhone.value
        }
        lib.sso.sendMsgCode({
          mobile,
          executing,
          executed
        })
      },
      toSubmit () {
        const me = this
        const executing = function () {
          me.btnOk.loading = true
        }

        const executed = function (res) {
          // console.log({ res })
          me.btnOk.loading = false
          if (res.status) {
            me.showAlert('success', '密码修改成功')
            // me.state = me.states.success
          } else {
            me.showAlert('error', res.data.desc || '密码修改失败')
            // me.state = me.states.error
          }
        }

        lib.sso.updatePasswordByCode({
          code: this.txtCode.value,
          newPassword: this.txtPassword2.value,
          executing,
          executed
        })
      },
      onSubmit () {
        if (!this.$refs.form.validate()) {
          return
        }

        const pwd1 = this.txtPassword1.value
        const pwd2 = this.txtPassword2.value
        if (pwd1 !== pwd2) {
          this.showAlert('error', '密码不一致')
          // this.$notify({
          //   title: '错误提示',
          //   message: '新密码与确认密码不一致，重新重新输入密码！',
          //   type: 'error'
          // })
          return
        }

        this.toSubmit()
      },
    }
  }
</script>
